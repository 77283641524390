import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SectionWrapper, WelcomeTexr, ButtonStyled, Box, PhaseItem, NavLinkA, TotalSupply, PartnerItem } from './Styled'
import Button from 'components/Button'
import { IoClose } from "react-icons/io5";
import SparkyImg1 from 'assets/images/SparkyImg-1.svg'
import SparkyImg2 from 'assets/images/SparkyImg-2.svg'
import Sparky from 'assets/images/Sparky.svg'
import Special from 'assets/images/Special.svg'
import Tokenomics from 'assets/images/Tokenomics.svg'
import Roadmap from 'assets/images/Roadmap.svg'
import Partner from 'assets/images/Partner.svg'
import Ton from 'assets/images/ton.png'
import TonViewer from 'assets/images/ton-chain-removebg-preview.png'
import Dedust from 'assets/images/dedust.png'
import DexView from 'assets/images/DEXView.svg'
import CoinGecko from 'assets/images/CoinGecko.svg'
import PinkSale from 'assets/images/PinkSale.svg'
import CoinMarket from 'assets/images/CoinMarketCap.svg'
import DexTool from 'assets/images/DexTools.svg'
import XLogo from 'assets/images/XLogo.svg'
import TeleLogo from 'assets/images/TeleLogo.svg'
import { PieChart } from 'react-minimal-pie-chart'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const PARTNERS = [
  { url: 'https://www.coingecko.com/', img: CoinGecko },
  { url: 'https://coinmarketcap.com/', img: CoinMarket },
]

const data = [
  { title: 'Presale', value: 1, color: '#3ff5e8' },
  { title: 'Liquidity', value: 1, color: '#154ba0' }
]

const TonRaffle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={34} viewBox='0 0 48 34' fill='none'>
      <path d="M40.6989 0L47.482 11.3333L40.6989 22.6667L47.482 34H33.9157L27.1326 22.6667L20.3494 34L13.5663 22.6667H27.1326L33.9157 11.3333H20.3494H6.78314L0 0H13.5663H27.1326H40.6989Z" fill='white' />
      <path d="M33.9157 33.9998L27.1326 22.6665H40.6989L33.9157 33.9998Z" fill='#8dccfc' />
      <path d="M47.4823 34.0003H33.916L40.6992 22.667L47.4823 34.0003Z" fill='#47a9f1'/>
      <path d="M13.5662 22.6665L20.3493 33.9998L27.1324 22.6665H13.5662Z" fill='#47a9f1'/>
      <path d="M27.1326 22.6668L33.9157 11.3335L40.6989 22.6668H27.1326Z" fill='#6ebeff'/>
      <path d="M47.4823 11.333L40.6992 22.6663L33.916 11.333H47.4823Z" fill='#47a9f1'/>
      <path d="M47.4823 11.3333L40.6992 0L33.916 11.3333H47.4823Z" fill='#2890db'/>
      <path d="M40.6989 0H27.1326L33.9157 11.3333L40.6989 0Z" fill='47a9f1'/>
      <path d="M20.3494 11.3333H33.9157L27.1325 0L20.3494 11.3333Z" fill='#6ebeff'/>
      <path d="M20.3493 11.3333L13.5662 0H27.1324L20.3493 11.3333Z" fill='#8dccfc'/>
      <path d="M20.3492 11.3333H6.78296L13.5661 0L20.3492 11.3333Z" fill='#47a9f1'/>
      <path d="M0 0H13.5663L6.78314 11.3333L0 0Z" fill='#6ebeff'/>
      <path opacity="0.4" d="M20.3494 11.3335H33.9157L27.1325 22.6668L20.3494 11.3335Z" fill='#75c1ff'/>
    </svg>
  )
}

const DexScreener = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" fill="#fff" fill-rule="evenodd" viewBox="0 0 252 300">
      <path 
        d="M151.818 106.866c9.177-4.576 20.854-11.312 
          32.545-20.541 2.465 5.119 2.735 9.586 1.465 
          13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 
          1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 
          46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 
          64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 
          5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 
          3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 
          3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 
          0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 
          8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 
          8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 
          65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 
          1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 
          5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 
          77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 
          106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 
          9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 
          3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"
      />
      <path 
        d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 
        4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 
        13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 
        39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 
        245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 
        3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 
        16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 
        1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 
        7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 
        14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 
        18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 
        41.862-100.816a41.865 41.865 0 
        015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 
        3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 
        8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"
      />
    </svg>
  )
}

function HomePage(): JSX.Element {

  return (
    <div>
      <div>
        <SectionWrapper>
          <WelcomeTexr>
            Welcome to Oscar Winning Dog
          </WelcomeTexr>
          <img src={Sparky} />
          <p className='text' style={{ margin: '1.5rem 0' }}>
            Love and hope empower us to achieve all our dreams.
          </p>
          <ButtonStyled>
            Get Started
          </ButtonStyled>
        </SectionWrapper>
      </div>
      <SectionWrapper>
        <WelcomeTexr style={{ textAlign: 'center' }}>
          Total Supply
        </WelcomeTexr>
        <TotalSupply>
          98.000.000.000
        </TotalSupply>
        <p className='text-md' style={{ marginTop: '2rem', textAlign: 'center' }}>
          Sparky has found its place in the hearts of humanity.
        </p>
        <p className='text-md' style={{ textAlign: 'center' }}>
          Investors may also find the answer to the buried treasure.
        </p>
        <p className='text-md' style={{ textAlign: 'center' }}>
          Come with us!
        </p>
      </SectionWrapper>
      <SectionWrapper id='why'>
        <div className='d-flex justify-content-center'>
          <img src={Special} />
        </div>
        <div className='row align-items-center'>
          <div className='col-sm-12 col-xl-5 d-flex justify-content-center'>
            <img src={SparkyImg1} />
          </div>
          <div className='col-sm-12 col-xl-7 d-flex justify-content-center gap-5'>
            <div className='d-flex justify-content-center align-items-center flex-wrap gap-5'>
              <Box>
                Community Driven Token
              </Box>
              <Box>
                Presale
              </Box>
              <Box>
                Beloved brand
              </Box>
              <Box>
                Pure Memes
              </Box>
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper id="tokenomics">
        <div className='d-flex justify-content-center'>
          <img src={Tokenomics} />
        </div>
        <div className='d-flex justify-content-center align-items-center gap-5 my-5'>
          <div style={{ width: '400px', height: '400px' }}>
            <PieChart
              data={data}
              lineWidth={20}
              paddingAngle={20}
              label={({ dataEntry }) => '50%'}
              labelStyle={(index) => ({
                fill: data[index].color,
                fontSize: '6px',
                fontFamily: 'SVN-Gilroy',
                color: '#ffff',
                fontWeight: '600'
              })}
              labelPosition={60}
              rounded
            />
          </div>
          <div className='d-flex flex-col justify-content-start align-items-start gap-3 my-5'>
            <div className='d-flex align-items-center gap-3'>
              <div style={{ width: '1rem', height: '2rem', borderRadius: '0.25rem', backgroundColor: '#3ff5e8' }}>
              </div>
              <p className='text'>Presale</p>
            </div>
            <div className='d-flex align-items-center gap-3'>
              <div style={{ width: '1rem', height: '2rem', borderRadius: '0.25rem', backgroundColor: '#154ba0' }}>
              </div>
              <p className='text'>Liquidity</p>
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper id='roadmap'>
        <div className='d-flex justify-content-center'>
          <img src={Roadmap} />
        </div>
        <div className='row mt-5 px-3'>
          <div className='col-sm-12 col-lg-6 col-xl-3 mt-5'>
            <PhaseItem>
              <div className='phase'>
                PHASE 1
              </div>
              <p className='text' style={{ fontWeight: '600', fontSize: '1.25rem' }}>
                START
              </p>
              <p className='text-sm'>
                &bull; Release Website & Social Media
              </p>
              <p className='text-sm'>
                &bull; Presale On Tonraffles
              </p>
              <p className='text-sm'>
                &bull; Pre-Launch Marketing
              </p>
              <p className='text-sm'>
                &bull; Launch on Dedust
              </p>
            </PhaseItem>
          </div>
          <div className='col-sm-12 col-lg-6 col-xl-3 mt-5'>
            <PhaseItem>
              <div className='phase'>
                PHASE 2
              </div>
              <p className='text' style={{ fontWeight: '600', fontSize: '1.25rem' }}>
                LAUNCH
              </p>
              <p className='text-sm'>
                &bull; Listing & Trending ON DEXs
              </p>
              <p className='text-sm'>
                &bull; Global Marketing Campaign
              </p>
              <p className='text-sm'>
                &bull; Mining Dapp Release
              </p>
              <p className='text-sm'>
                &bull; Listing CMC/CGK
              </p>
              <p className='text-sm'>
                &bull; Listing CEXs Campaign
              </p>
            </PhaseItem>
          </div>
          <div className='col-sm-12 col-lg-6 col-xl-3 mt-5'>
            <PhaseItem>
              <div className='phase'>
                PHASE 3
              </div>
              <p className='text' style={{ fontWeight: '600', fontSize: '1.25rem' }}>
                DEVELOP
              </p>
              <p className='text-sm'>
                &bull; Swap Dapp Release
              </p>
              <p className='text-sm'>
                &bull; Staking Dapp Release
              </p>
              <p className='text-sm'>
                &bull; GameFi Release
              </p>
            </PhaseItem>
          </div>
          <div className='col-sm-12 col-lg-6 col-xl-3 mt-5'>
            <PhaseItem>
              <div className='phase'>
                PHASE 4
              </div>
              <p className='text' style={{ fontWeight: '600', fontSize: '1.25rem', color: '#7cd8f8' }}>
                COMING SOON...
              </p>
            </PhaseItem>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper id="partners" style={{ paddingBottom: '0' }}>
        <div className='d-flex justify-content-center'>
          <img src={Partner} />
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4 d-flex justify-content-center'>
            <PartnerItem onClick={() => window.open('https://ton.org/', '_blank')}>
              <img src={Ton} style={{ width: '18%', height: '55%'}}/>
              <p>TON</p>
            </PartnerItem>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4 d-flex justify-content-center'>
            <PartnerItem onClick={() => window.open('https://tonviewer.com/', '_blank')}>
              <img src={TonViewer} style={{ width: '25%', height: '80%'}}/>
              <p>Tonviewer</p>
            </PartnerItem>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4 d-flex justify-content-center'>
            <PartnerItem onClick={() => window.open('https://dedust.io/', '_blank')}>
              <img src={Dedust} style={{ width: '20%', height: '70%'}}/>
              <p>Dedust</p>
            </PartnerItem>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4 d-flex justify-content-center'>
            <PartnerItem onClick={() => window.open('https://dexscreener.com/', '_blank')}>
              <DexScreener />
              <p>DEX <span style={{fontWeight: '400'}}>SCREENER</span></p>
            </PartnerItem>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4 d-flex justify-content-center'>
            <PartnerItem onClick={() => window.open('https://testnet.tonraffles.app/', '_blank')}>
              <TonRaffle />
              <p>TonRaffles</p>
            </PartnerItem>
          </div>
          {PARTNERS.map((item) => (
            <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4 d-flex justify-content-center'>
              <a href={item.url} target='_blank' style={{ cursor: 'pointer' }}>
                <img src={item.img} />
              </a>
            </div>
          ))}
        </div>
        <div className='d-flex justify-content-center' style={{ marginTop: '3rem' }}>
          <img src={SparkyImg2} />
        </div>
        <p className='text-md' style={{ textAlign: 'center' }}>
          Become Frankenweebier and to the moon
        </p>
      </SectionWrapper>
      <SectionWrapper id='community' style={{ padding: '1.5rem' }}>
        <div className='d-flex justify-content-center align-items-center gap-5 p-3' style={{ borderTop: '1px solid #fff', borderBottom: '1px solid #fff' }}>
          <a href='http://twitter.com/sparky_ton' target='_blank'>
            <FaXTwitter color='#fff' size={50} />
          </a>
          <a href='https://t.me/SPARKYOFFICIAL' target='_blank'>
            <FaTelegramPlane color='#fff' size={50} />
          </a>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-wrap gap-5 p-4'>
          <NavLinkA href='/'>
            Home
          </NavLinkA>
          <NavLinkA href='/#why'>
            Why buy
          </NavLinkA>
          <NavLinkA href='/#tokenomics'>
            Tokenomics
          </NavLinkA>
          <NavLinkA href='/#roadmap'>
            Roadmap
          </NavLinkA>
          <NavLinkA href='/#partners'>
            Partners
          </NavLinkA>
          <NavLinkA href='/#community'>
            Community
          </NavLinkA>
        </div>
        <p className='text-md mt-3' style={{ textAlign: 'center' }}>
          Sparky 2024
        </p>
      </SectionWrapper>
    </div>
  )
}

export default HomePage
