import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  HeaderMain,
  HeaderWrapper,
  TitleWapper,
  MenuToggle,
  NavBarWrap,
  HeaderMobile,
  NavLinkA,
  ButtonWrapper,
  JoinButton
} from './Styled'

import { FiMenu } from 'react-icons/fi'
import Logo from 'assets/images/telegram-cloud-photo-size-5-6174860853984212662-x-removebg-preview (2).png'
import Button from 'components/Button'

function HeaderSecond(): JSX.Element {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  return (
    <HeaderMain>
      <HeaderWrapper>
        <NavLinkA href="/">
          Home
        </NavLinkA>
        <NavLinkA href="/#why">
          Why buy
        </NavLinkA>
        <NavLinkA href="/#tokenomics">
          Tokenomics
        </NavLinkA>
        <NavLinkA href="/#roadmap">
          Roadmap
        </NavLinkA>
        <NavLinkA href="/#partners">
          Partners
        </NavLinkA>
        <NavLinkA href="/#community">
          Community
        </NavLinkA>
        <JoinButton onClick={() => window.open('https://t.me/SPARKYOFFICIAL', '_blank')}>
          Join us
        </JoinButton>
      </HeaderWrapper>
      <HeaderMobile className="container">
        <MenuToggle onClick={handleMobileMenuOpen}>
          <FiMenu size={24} color='#fff'/>
        </MenuToggle>
        <Offcanvas show={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)}>
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLinkA href="/">Home</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#why" >Why buy</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#tokenomics" >Tokenomics</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#roadmap" >Roadmap</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#partners" >Partners</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#community" >Community</NavLinkA>
            </li>
            <li>
              <NavLinkA href="https://t.me/SPARKYOFFICIAL" target='_blank' >Join us</NavLinkA>
            </li>
          </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </HeaderMobile>
    </HeaderMain>
  )
}

export default HeaderSecond
