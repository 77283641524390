import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  createWeb3ReactRoot,
  Web3ReactProvider,
} from "@dotoracle/web3-react-core";
import { Web3Provider } from "@ethersproject/providers";
import { NetworkContextName } from "./constants";
import App from "./App";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 15000;
  return library;
};


ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <App />
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
