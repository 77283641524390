import styled, { keyframes } from 'styled-components/macro'

export const SectionWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 10rem 0;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (min-width: 992px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
    width: 75%;
  }
`

export const ButtonStyled = styled.button`
  background: rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  border: 1px solid #7cd8f8;
  color: #fff;
  font-family: SVN-Gilroy;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1rem 2.5rem;
`
export const ButtonConnect = styled.button`
  background: transparent;
  border-top: 3px solid #fff;
  border-left: 3px solid rgb(135, 135, 135);
  border-right: 3px solid #fff;
  border-bottom: 3px solid rgb(135, 135, 135);
  color: #000;
  font-family: Pixelify Sans;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
  margin: 2rem auto 0;
`
export const Box = styled.div`
  width: 80%;
  height: 7rem;
  padding: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: rgba(124, 216, 248, 0.1);
  font-family: SVN-Gilroy;
  font-size: 1.5rem;
  font-weight: 550;
  color: #fff;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  word-spacing: 0.075rem;

  @media (min-width: 503px) {
    width: 45%;
  }
`
export const WelcomeTexr = styled.p`
  color: #7cd8f8;
  font-family: SVN-Gilroy;
  font-size: 3rem;
  font-weight: 550;
  line-height: 1.75rem;
`
export const PhaseItem = styled.div`
  background-color: transparent;
  border-radius: 0.75rem;
  border: 1px solid #fff;
  position: relative;
  padding: 1.5rem;
`
export const NavLinkA = styled.a`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 18px;
  color: #fff;
  font-family: SVN-Gilroy;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.PrimaryColor2};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`
export const TotalSupply = styled.p`
  font-family: SVN-Gilroy;
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-top: 1rem;

  @media (min-width: 992px) {
    font-size: 4rem;
  }
`
export const PartnerItem = styled.div`
  width: 16rem;
  height: 5rem;
  border-radius: 99px;
  border: 1px solid #dadfee;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  img {
    width: 90%;
    height: 130%;
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: SVN-Gilroy;
    color: #fff;
  }
`