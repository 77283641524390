import { TorusConnector } from '@dotoracle/web3-react-torus-connector-casper'
import { isDev } from 'utils'

export enum ConnectorNames {
  CasperSigner = 'caspersigner',
  TorusWallet = 'toruswallet',
  CasperWallet = 'casperwallet',
  CasperDash = 'casperdash',
  WalletConnect = 'walletconnect'
}

const CASPER_CHAINS = {
  CASPER_MAINNET: 'casper',
  CASPER_TESTNET: 'casper-test',
}

const SUPPORTED_NETWORKS = {
  [CASPER_CHAINS.CASPER_MAINNET]: {
    blockExplorerUrl: 'https://cspr.live',
    chainId: '131614895977472',
    displayName: 'Casper Mainnet',
    logo: 'https://cspr.live/assets/icons/logos/cspr-live-full.svg',
    rpcTarget: 'https://casper-node.tor.us',
    ticker: 'CSPR',
    tickerName: 'Casper Token',
    networkKey: CASPER_CHAINS.CASPER_MAINNET,
  },
  [CASPER_CHAINS.CASPER_TESTNET]: {
    blockExplorerUrl: 'https://testnet.cspr.live',
    chainId: '96945816564243',
    displayName: 'Casper Testnet',
    logo: 'https://testnet.cspr.live/assets/icons/logos/cspr-live-full.svg',
    rpcTarget: 'https://testnet.casper-node.tor.us',
    ticker: 'CSPR',
    tickerName: 'Casper Token',
    networkKey: CASPER_CHAINS.CASPER_TESTNET,
  },
}

export const torus = new TorusConnector({
  chainId: isDev ? '96945816564243' : '131614895977472',
  initOptions: {
    showTorusButton: false,
    network: isDev
      ? SUPPORTED_NETWORKS[CASPER_CHAINS.CASPER_TESTNET]
      : SUPPORTED_NETWORKS[CASPER_CHAINS.CASPER_MAINNET],
  },
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.CasperSigner]: 'caspersigner',
  [ConnectorNames.TorusWallet]: 'toruswallet',
  [ConnectorNames.CasperWallet]: 'casperwallet',
  [ConnectorNames.CasperDash]: 'casperdash',
  [ConnectorNames.WalletConnect]: 'walletconnect'
}

// https://github.com/make-software/casper-wallet-sdk
export type CasperWalletState = {
  isLocked: boolean
  isConnected: boolean
  activeKey: string | null
}

export enum CasperWalletEventTypes {
  Connected = 'casper-wallet:connected',
  Disconnected = 'casper-wallet:disconnected',
  TabChanged = 'casper-wallet:tabChanged',
  ActiveKeyChanged = 'casper-wallet:activeKeyChanged',
  Locked = 'casper-wallet:locked',
  Unlocked = 'casper-wallet:unlocked',
}
